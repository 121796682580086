import { APP_INITIALIZER, importProvidersFrom, inject, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RxState } from '@rx-angular/state';

import {
    HDMUI_THEMES,
    HdmuiComponentsModule,
    HdmuiScreenBlockerModule,
    provideHdmui,
    ThemeService,
} from '@heidelberg/hdmui-angular';
import { ConfigService, ControlCenterNotificationModule } from '@heidelberg/control-center-frontend-integration';
import {
    CC_APP_AUTH_CONFIG,
    CCAppAuthConfig,
    CCAuthService,
    provideCCAuth,
} from '@heidelberg/control-center-frontend-integration/auth';

import {
    LoadingIndicatorComponentModule,
    SignInConnectIndicatorComponentModule,
} from '@data-terminal/ui-presentational';
import {
    AcceptHeaderInterceptor,
    FooterVisibleDirective,
    LoadingSpinnerInterceptor,
    LoadingStateService,
    SnackBarModule,
    THOUSAND_SEPARATOR,
} from '@data-terminal/utils';
import { FeatureTransitionModule } from '@data-terminal/feature-transition';
import {
    AppConfigService,
    BACKEND_URL,
    GLOBAL_RX_STATE,
    GlobalErrorHandler,
    GlobalState,
    ORGANIZATION_REQUEST_PREFIX,
    OrganizationUrlInterceptor,
} from '@data-terminal/data-access';

import { ROUTES } from './app.routes';
import { AppComponent } from './components/app/app.component';
import { FooterComponentModule } from './components/footer/footer.component';
import { NavigationComponentModule } from './components/navigation/navigation.component';
import { ToolbarComponentModule } from './components/toolbar/toolbar.component';
import { LegalLineComponent } from './components/legal-line/legal-line.component';
import { createTranslateLoader, getLocale, getLocaleForDatePicker, initLangSettings } from './locale.support';
import { environment } from '../environments/environment';
import { NgxMaskConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Ga4Service } from './public-api';

@NgModule({
    declarations: [AppComponent, FooterVisibleDirective],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(ROUTES, {
            paramsInheritanceStrategy: 'always',
            useHash: true,
            onSameUrlNavigation: 'reload',
        }),
        ToolbarComponentModule,
        MatSidenavModule,
        HdmuiComponentsModule,
        FooterComponentModule,
        MatDialogModule,
        NavigationComponentModule,
        LoadingIndicatorComponentModule,
        SignInConnectIndicatorComponentModule,
        SnackBarModule,
        FeatureTransitionModule,
        MatTabsModule,
        LegalLineComponent,
        HdmuiScreenBlockerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        provideHdmui(),
        provideHttpClient(withInterceptorsFromDi()),
        importProvidersFrom(ControlCenterNotificationModule),
        provideCCAuth(),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AcceptHeaderInterceptor,
            multi: true,
        },
        {
            provide: CC_APP_AUTH_CONFIG,
            useFactory: createAuthConfig,
            deps: [AppConfigService, ConfigService],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: onThemeInit,
            multi: true,
            deps: [ThemeService],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initLangSettings,
            multi: true,
            deps: [TranslateService, CCAuthService],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initGA4Service,
            deps: [Ga4Service],
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useFactory: getLocale,
            deps: [TranslateService],
        },
        {
            provide: MAT_DATE_LOCALE,
            useFactory: getLocaleForDatePicker,
            deps: [TranslateService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OrganizationUrlInterceptor,
            multi: true,
            deps: [CCAuthService, ORGANIZATION_REQUEST_PREFIX],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingSpinnerInterceptor,
            multi: true,
            deps: [LoadingStateService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GlobalErrorHandler,
            deps: [Injector, CCAuthService, TranslateService, Router],
            multi: true,
        },
        { provide: GLOBAL_RX_STATE, useFactory: () => new RxState<GlobalState>() },
        provideEnvironmentNgxMask(createMaskConfig),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

function createAuthConfig(service: AppConfigService, configService: ConfigService): CCAppAuthConfig {
    return {
        ccStage: service.getCCStage(),
        clientId: service.getOAuthClientId(),
        appId: environment.APP_NAME,
        requiresOrganizationContext: true,
        authenticatedUrls: [
            '/api',
            inject(BACKEND_URL),
            window.location.origin + '/',
            configService.getNotificationSvcUrl(),
        ],
    };
}

function onThemeInit(service: ThemeService): () => void {
    return () => {
        const queryString = window.location.search;
        if (queryString) {
            const urlParams = new URLSearchParams(queryString);
            const theme = urlParams.get('theme');
            if (
                theme &&
                [HDMUI_THEMES.DARK_THEME_CLASS.toString(), HDMUI_THEMES.LIGHT_THEME_CLASS.toString()].includes(theme)
            ) {
                service.setTheme(theme);
            }
        }
    };
}

function createMaskConfig(): Partial<NgxMaskConfig> {
    const thousandSeparator = inject(THOUSAND_SEPARATOR);
    return {
        thousandSeparator,
    };
}

function initGA4Service(service: Ga4Service): () => void {
    return () => {
        service.listenToEvents();
    };
}
