<hdmui-cloud-toolbar
    #cloudToolbar
    [currentUser]="cloudToolbarUser"
    [appTitle]="(currentPageTitle$ | async)! | translate"
    [showSideBarButton]="true"
    [showSidenavSettingsButton]="true"
    (sidenavAboutClick)="onAboutClick()"
    [isSecondLevel]="(isSecondLevel$ | async)!"
    [appId]="appClientId"
    [ccStage]="controlCenterStage"
    [appStage]="appStage"
    [breadcrumbs]="breadcrumbs$ | async"
    (backButtonClick)="goBack()"
    (logoutClick)="doLogout()"
    [sidenavSettingsRouterLink]="[orgId, 'settings']"
    [showNotificationButton]="true"
    [notifications]="notifications"
    [appCategory]="'*.appCategory.production' | translate"
    [appIcon]="'assets/icons/HDM-Data-Capture-Nearlywhite.svg'"
    (notificationsRemove)="onNotificationRemove($event)"
>
    <div sidenavContent>
        <mat-nav-list>
            <mat-list-item
                (click)="onNotificationsSettingsClick(); cloudToolbar.sidenav?.close()"
                [matTooltip]="'NOTIFICATIONS_SETTINGS.TITLE' | translate"
            >
                <mat-icon matListItemIcon svgIcon="hdmui:bell"></mat-icon>
                {{ 'NOTIFICATIONS_SETTINGS.TITLE' | translate }}
            </mat-list-item>
            <mat-list-item
                (click)="onBatchInformationClick(); cloudToolbar.sidenav?.close()"
                [matTooltip]="'BATCH_INFORMATION.TITLE' | translate"
            >
                <mat-icon matListItemIcon svgIcon="hdmui:export"></mat-icon>
                {{ 'BATCH_INFORMATION.TITLE' | translate }}
            </mat-list-item>
        </mat-nav-list>
    </div>
    @if (isChatbotAllowed()) {
        <div customButtonContainer>
            <button [matMenuTriggerFor]="advancedMenu" mat-icon-button>
                <mat-icon svgIcon="hdmui:help"></mat-icon>
            </button>
            <mat-menu #advancedMenu="matMenu">
                <button mat-menu-item (click)="openLink('documentation')">
                    <mat-icon svgIcon="hdmui:help"></mat-icon>
                    <span> {{ 'DC.DASHBOARD_TOOLBAR.DOCS' | translate }}</span>
                </button>
                <button mat-menu-item (click)="openLink('chatbot')">
                    <mat-icon svgIcon="hdmui:help"></mat-icon>
                    <span> {{ 'DC.DASHBOARD_TOOLBAR.CHATBOT' | translate }}</span>
                </button>
            </mat-menu>
        </div>
    }
</hdmui-cloud-toolbar>
