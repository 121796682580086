import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, interval, map, Observable, switchMap, take, takeWhile, throwError } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateTime } from 'luxon';

import { DataTerminalSessionService } from '@data-terminal/data-access';
import {
    AbstractSignOffProcessService,
    EndShiftProcessService,
    FooterLeaveDialogComponent,
    SignOffProcessService,
} from '@data-terminal/feature-dialogs';
import {
    AvailableAssistants,
    FooterDialogsActions,
    Role,
    SetAssistantsEntry,
    User,
} from '@data-terminal/shared-models';
import {
    HplusRedirectionService,
    LoadingStateService,
    Required,
    TimeFormatterPipe,
    TimeFormatterPipeModule,
} from '@data-terminal/utils';
import { FeatureDashboardDataAccessModule } from '@data-terminal/feature-dashboards-data-access';
import { InactivityPageRedirectionService, InactivityTrackerService } from '@data-terminal/feature-inactivity-tracker';

import {
    ManageAssistantsDialogComponent,
    ManageAssistantsDialogData,
} from '../../../../projects/feature-dialogs/src/lib/dialogs/manage-assistants-dialog/manage-assistants-dialog.component';
import { UserMachinesService } from '../../../../projects/data-access/src/lib/user-machines';

@UntilDestroy()
@Component({
    providers: [TimeFormatterPipe],
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    @Input()
    @Required()
    activatedRoute?: ActivatedRoute;

    @Input()
    availableAssistants: AvailableAssistants[] = [];

    @Input()
    isTablet = false;

    public inactiveWorkstations$ = this.inactivityTrackerService.getInactiveWorkstations$();

    public user!: User;
    public machineId = '';
    public machineSignInTimestamp: number | undefined;
    public machineSignInTimer$!: Observable<number | undefined>;
    public inactivityTimer$: Observable<number> | undefined;
    public displayFooterTime = false;

    constructor(
        private readonly dialog: MatDialog,
        private readonly session: DataTerminalSessionService,
        private readonly signOffProcess: SignOffProcessService,
        private readonly endShiftProcess: EndShiftProcessService,
        private readonly loadingStateService: LoadingStateService,
        private readonly hplusRedirectionService: HplusRedirectionService,
        private readonly inactivityTrackerService: InactivityTrackerService,
        private readonly userMachinesService: UserMachinesService,
        private readonly router: Router,
        private readonly snackbar: MatSnackBar,
        private readonly translateService: TranslateService
    ) {
        this.loadingStateService.isProfileLoading$.pipe(take(2)).subscribe(() => {
            this.user = this.session.getCurrentDataTerminalUser();
        });
        this.router.events
            .pipe(
                filter((event) => {
                    return event instanceof ActivationEnd;
                })
            )
            .subscribe((route) => {
                this.setDataFromRoute((route as ActivationEnd).snapshot);
            });
        this.setDataFromRoute(this.router.routerState.snapshot.root);

        this.inactiveWorkstations$.pipe(untilDestroyed(this)).subscribe(() => {
            this.getFooterInactivityData();
        });

        interval(1000).subscribe(() => this.getFooterInactivityData());
    }

    private setDataFromRoute(route: ActivatedRouteSnapshot): void {
        this.machineId = this.getMachineId(route || this.machineId);
        this.machineSignInTimestamp = this.getMachineSignInTimestamp(route);
        this.machineSignInTimer$ = interval(1000).pipe(
            takeWhile(() => !!this.machineSignInTimestamp, !!this.machineId),
            map(() =>
                this.machineSignInTimestamp
                    ? DateTime.now().diff(DateTime.fromMillis(this.machineSignInTimestamp)).milliseconds
                    : 0
            )
        );
    }

    private getFooterInactivityData(): void {
        this.inactivityTimer$ = this.inactivityTrackerService.getInactiveWorkstationsTimersMap().get(this.machineId);
        this.displayFooterTime = this.inactivityTrackerService.getInactiveWorkstationsTimersMap().has(this.machineId);
    }

    private getMachineId(route: ActivatedRouteSnapshot): string {
        if (route.firstChild === undefined) {
            return '';
        }
        if (route.firstChild === null) {
            return route?.params?.machineId;
        }

        return this.getMachineId(route.firstChild);
    }

    private getMachineSignInTimestamp(route: ActivatedRouteSnapshot): number | undefined {
        if (route.firstChild === undefined) {
            return undefined;
        }

        if (route.firstChild === null) {
            return route?.data?.machineSignInTimestamp;
        }

        return this.getMachineSignInTimestamp(route.firstChild);
    }

    public onLeaveButtonClick(): void {
        const config = new MatDialogConfig();
        config.width = '99vw';
        config.maxWidth = '99vw';
        config.height = '99vh';
        config.maxHeight = '99vh';

        const dialog = this.dialog.open(FooterLeaveDialogComponent, config);
        dialog.componentInstance.hasUserActiveMachines = this.user && this.user.userMachines?.length > 0;

        dialog
            .beforeClosed()
            .pipe(filter((data: FooterDialogsActions | false) => data !== false))
            .subscribe((data) => {
                switch (data) {
                    case FooterDialogsActions.SIGN_OFF_WORKSTATION: {
                        this.startSignOffProcess();
                        break;
                    }
                    case FooterDialogsActions.END_SHIFT: {
                        this.startEndShiftProcess();
                        break;
                    }
                    case FooterDialogsActions.CLOSE_APPLICATION: {
                        this.hplusRedirectionService.redirect();
                        break;
                    }
                    case FooterDialogsActions.MANAGE_ASSISTANTS: {
                        this.startAssistantsManagementProcess();
                        break;
                    }
                    default:
                        break;
                }
            });
    }

    private startSignOffProcess(): void {
        this.startGenericProcess(this.signOffProcess, 'DC.SIGN_OFF_DIALOG');
    }

    private startEndShiftProcess(): void {
        this.startGenericProcess(this.endShiftProcess, 'DC.END_SHIFT_DIALOG', true);
    }

    private startGenericProcess(
        process: AbstractSignOffProcessService,
        i18nPrefix: string,
        endApplication = false
    ): void {
        process.startProcess(this.user, i18nPrefix, endApplication).subscribe();
    }

    private startAssistantsManagementProcess(): void {
        const data: ManageAssistantsDialogData = {
            workstations: this.user.userMachines.filter((machine) =>
                machine.signedOnUsers.some(
                    (user) => user.userId === this.user.userId && (user.role === Role.OPERATOR || user.captain)
                )
            ),
            userId: this.user.userId,
            availableAssistants: this.availableAssistants,
        };

        const dialogConfig: MatDialogConfig<ManageAssistantsDialogData> = {
            disableClose: true,
            width: '680px',
            height: '80%',
            data,
        };

        const dialog: MatDialogRef<ManageAssistantsDialogComponent, SetAssistantsEntry[]> = this.dialog.open(
            ManageAssistantsDialogComponent,
            dialogConfig
        );

        dialog
            .afterClosed()
            .pipe(
                take(1),
                filter((setAssistantsEntries) => !!setAssistantsEntries?.length),
                map((setAssistantsEntries) => setAssistantsEntries as SetAssistantsEntry[]),
                switchMap((setAssistantsEntries) =>
                    this.userMachinesService.setAssistants(setAssistantsEntries).pipe(
                        map((userMachines) => {
                            if (userMachines.error) {
                                return throwError(() => userMachines.error?.message);
                            } else {
                                return userMachines;
                            }
                        })
                    )
                )
            )
            .subscribe({
                error: (err) => {
                    this.snackbar.open(
                        this.translateService.instant(err),
                        this.translateService.instant('*.action.close'),
                        {
                            duration: 3000,
                            panelClass: 'hdmui-warning',
                        }
                    );
                },
            });
    }
}

@NgModule({
    declarations: [FooterComponent],
    exports: [FooterComponent],
    providers: [InactivityTrackerService, InactivityPageRedirectionService],
    imports: [
        MatIconModule,
        MatDividerModule,
        MatToolbarModule,
        MatButtonModule,
        TranslateModule,
        CommonModule,
        FeatureDashboardDataAccessModule,
        TimeFormatterPipeModule,
    ],
})
export class FooterComponentModule {}
