import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTabNavPanel } from '@angular/material/tabs';
import { Observable } from 'rxjs';

import { OperationNavigationService } from '@data-terminal/data-access';
import { NavLink, OperationNavConfig } from '@data-terminal/shared-models';

@Component({
    selector: 'app-operation-navigation-tab',
    templateUrl: './operation-navigation-tab.component.html',
    styleUrls: ['./operation-navigation-tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNavigationTabComponent {
    @Input() tabPanel!: MatTabNavPanel;
    @Input() links!: NavLink[];
    @Input() isSecondLevel!: boolean;

    public operationTabsConfig$: Observable<OperationNavConfig>;

    constructor(private readonly operationNavigation: OperationNavigationService) {
        this.operationTabsConfig$ = this.operationNavigation.getOperationTabsConfig$();
    }

    public isLinkDisabled(link: NavLink, config: OperationNavConfig): boolean {
        return !!(link.id && config[link.id].isDisabled);
    }
}
